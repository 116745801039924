/* eslint-disable */
import { Vue } from 'vue-class-component';
import { Action, Getter, Mutation, State, namespace } from 'vuex-class';
import { toBase64 } from '@/services/api.upload';
import { ModelAccountState } from '@/store/modelAccount/types';
import { ModelContacts } from '@/components/pages/model-account/contact-information/model-account-contact-information.interface'
import router from "@/route/routing";
import * as header from '@/components/organisms/header/header.ts';
import * as locker from "@/store/locker.js"
import $ from "jquery";
import Dropbox from "@/store/dropbox.js"
import * as messageModal from "@/store/message-modal.js"

const nModelAccount: string = 'modelAccount';
const customer = namespace('customer');

export default class ModelAccountContactInformationPage extends Vue {
    @customer.Mutation('setIsLogin') setIsLogin: any;

    @Action('getRegions', {namespace: nModelAccount}) getRegions: any;
    @Action('getCountries', {namespace: nModelAccount}) getCountries: any;
    @Action('getCities', {namespace: nModelAccount}) getCities: any;

    @Getter('getCountriesData', {namespace: nModelAccount}) getCountriesData: any;
    @Getter('getRegionsData', {namespace: nModelAccount}) getRegionsData: any;
    @Getter('getCitiesData', {namespace: nModelAccount}) getCitiesData: any;

    @Getter('getModelData', {namespace: nModelAccount}) getModelData: any;

	@State('modelAccount') modelAccount: ModelAccountState | undefined;
	@Action('setContactInformation', {namespace: nModelAccount}) setContactInformation: any;
	@Action('uploadFile', {namespace: nModelAccount}) uploadFile: any;

    @Mutation('clearRegionsData', {namespace: nModelAccount}) clearRegionsData: any;
    @Mutation('clearCountriesData', {namespace: nModelAccount}) clearCountriesData: any;
    @Mutation('clearCitiesData', {namespace: nModelAccount}) clearCitiesData: any;

	$refs!: {
		doc: HTMLInputElement,
		face: HTMLInputElement,
		faceDoc: HTMLInputElement,
        selectCountry: HTMLInputElement,
		selectRegion: HTMLInputElement,
        selectCity: HTMLInputElement
	};

	modelContacts: any = {
		userName: "",
		name: {value: "", require: true, class: ""},
		lastName: {value: "", require: true, class: ""},
		country: "",
		region: "",
		city: "",
		address: "",
		postcode: "",
		phoneNumber:  {value: "", require: true, class: ""},
		documentDate: "",
		nonUrgentDoc: false,
		birthday: {value: "", require: true, class: ""},
		fileDocument: "",
		fileFace: "",
		fileFaceDocument: ""
	};

	docValue: string = '';
	faceValue: string = '';
	faceDocValue: string = '';

    Dropbox(e : any) {
    	Dropbox(e);
    }

	//Страна
    ChangeCountry(e : Event)
	{
        console.log('ChangeCountry', this.modelContacts.country);

        if (this.modelContacts.country)
        {
            this.getRegions(this.modelContacts.country);

            this.modelContacts.region = "";
            this.modelContacts.city = "";

            $('#region-name').val('');
            $('#city-name').val('');

            this.clearCitiesData();
        }
	}

    //Регион
    ChangeRegion(e : Event)
    {
        console.log('ChangeRegion', this.modelContacts.region);

        if (this.modelContacts.region)
        {
            this.getCities({
                country_id: this.modelContacts.country,
                region_id : this.modelContacts.region
            });

            this.modelContacts.city = "";
            $('#city-name').val('');
        }
    }

	//Регион
    handleChangeRegion(e: Event) {
        if ((e.target as HTMLLIElement).nodeName === 'LI') {
            this.$refs.selectRegion.value = (e.target as HTMLLIElement).innerText;

            if (this.modelContacts.region != (e.target as HTMLLIElement).id)
			{
                this.modelContacts.region = (e.target as HTMLLIElement).id;
                this.getCities({
                    country_id: this.modelContacts.country,
                    region_id : this.modelContacts.region
                });

                this.$refs.selectCity.value = "";
			}
        }
    }

    //Город
    handleChangeCity(e: Event) {
        if ((e.target as HTMLLIElement).nodeName === 'LI') {
            this.$refs.selectCity.value = (e.target as HTMLLIElement).innerText;
            this.modelContacts.city = (e.target as HTMLLIElement).id;
        }
    }

    PhoneFilter(e : any)
	{
        this.modelContacts.phoneNumber.class = '';
        this.modelContacts.phoneNumber.value = this.modelContacts.phoneNumber.value.replace(/[^+\d\s\-()]/g, '');
	}

	async handleFileUpload(file: string) {
		switch(file) {
		case 'doc':
			const doc = this.$refs.doc.files![0];
			this.docValue = this.$refs.doc.value;
			return await toBase64(doc).then(data => this.modelContacts.fileDocument = data);
		case 'face':
			const face = this.$refs.face.files![0];
			this.faceValue = this.$refs.face.value;
			return await toBase64(face).then(data => this.modelContacts.fileFace = data);
		case 'faceDoc':
			const faceDoc = this.$refs.faceDoc.files![0];
			this.faceDocValue = this.$refs.faceDoc.value;
			return await toBase64(faceDoc).then(data => this.modelContacts.fileFaceDocument = data);
		default:
			console.log('Error upload file!');
			break;
		}
	}

	onSubmit() {
        var _this = this;



		var documentDate : any = "";
		if (this.modelContacts.documentDate)
            documentDate = Date.parse(this.modelContacts.documentDate);
		if (this.modelContacts.nonUrgentDoc)
            documentDate = -1;

		//Валидация
		var errors = 0;
        Object.keys(this.modelContacts).forEach(function(key) {
            if (_this.modelContacts[key].require && !_this.modelContacts[key].value.trim())
			{
                _this.modelContacts[key].class = "error";
                errors++;
			}
        });

        if (errors > 0) {
            messageModal.Show("Ошибка", "Заполните обязательные поля формы");
            return;
        }

        if (this.modelContacts.birthday.value && (Date.now() - Date.parse(this.modelContacts.birthday.value)) < 567993600000)
		{
            messageModal.Show("Ошибка", "Ваш возраст должен быть старше 18 лет");
            this.modelContacts.birthday.class = "error";
            errors++;
            return;
		}

        if (errors > 0) {
            return;
		}

        locker.Show();

		//Посылаем данные на сохранение
		var data = {
            //nickname : this.modelContacts.userName,
            name: this.modelContacts.name.value,
            last_name: this.modelContacts.lastName.value,
            phone: this.modelContacts.phoneNumber.value,

            country_id: this.modelContacts.country,
            region_id: this.modelContacts.region,
            city_id: this.modelContacts.city,

            address: this.modelContacts.address,
            postcode: this.modelContacts.postcode,

            document_photo: this.modelContacts.fileDocument,
            face_photo: this.modelContacts.fileFace,
            face_with_doc_photo: this.modelContacts.fileFaceDocument,
            document_available: documentDate,
            birthday: Date.parse(this.modelContacts.birthday.value),
        };

        console.log('data', data);

		this.setContactInformation(data).then(function () {
            locker.Hide();
            router.push({name: 'model-account-profile-information'}).then(r => r);
        });
	}

    mounted() {
        messageModal.Init();

        this.setIsLogin(true);
		this.getCountries();
    }
}
